/* eslint-disable react/no-danger */
import React, {ReactElement} from 'react';
import {graphql} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

interface MarkdownTemplateProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        updated: string;
      };
      html: string;
    };
  };
}

export default function MarkdownTemplate({data}: MarkdownTemplateProps): ReactElement {
  const {markdownRemark} = data;
  const {frontmatter, html} = markdownRemark;
  const {title, updated} = frontmatter;

  const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};
  const [year, month, day] = updated.split('-');
  const updatedDate = new Date(Number(year), Number(month) - 1, Number(day));
  const lastUpdated = updatedDate.toLocaleDateString('en-US', dateOptions);
  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <em>Last updated: {lastUpdated}</em>
      <div dangerouslySetInnerHTML={{__html: html}} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      frontmatter {
        path
        title
        updated
      }
      html
    }
  }
`;
